import Swal from "sweetalert2";
<template>
    <v-app style="background: transparent">
        <v-card>
            <v-card-title>
                Usuarios
                <v-spacer></v-spacer>
                <v-text-field
                        v-model="search"
                        append-icon="search"
                        label="Buscar"
                        single-line
                        hide-details
                ></v-text-field>
            </v-card-title>
            <v-data-table
                    class="elevation-1"
                    :loading="loading" loading-text="Loading... Please wait"
                    :headers="headers"
                    :items="users"
                    :search="search">
                <template v-slot:item.business_info.contratos_generados="{ item }">
                    <v-chip color="green" dark>{{ item.business_info.contratos_generados }}</v-chip>
                </template>
                <template v-slot:item.actions="{ item }">
                    <v-icon
                            color="blue darken-2"
                            small
                            class="mr-2"
                            @click="editItem(item)"
                    >
                        mdi-pencil
                    </v-icon>
                    <v-icon
                            color="orange darken-2"
                            small
                            @click="confirmDelete(item)"
                    >
                        mdi-delete
                    </v-icon>
                </template>

            </v-data-table>
            <v-row align="end">
                <v-col cols="12" sm="10"></v-col>
                <v-col cols="12" sm="2">
                    <div class="align-content-xl-end">
                        <v-btn style="width: 90%" @click="newUser" v-b-modal.modal-1 outlined x-large color="primary"
                               dark>Nuevo usuario
                        </v-btn>
                    </div>
                </v-col>

            </v-row>
            <v-dialog v-model="dialog"
                      max-width="290">
                <v-card>
                    <v-card-title class="headline">Eliminar Usuario</v-card-title>
                    <v-card-text>
                        Se eliminará el usuario
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="green darken-1"
                               text
                               @click="deleteItem()">
                            Aceptar
                        </v-btn>
                        <v-btn color="green darken-1"
                               text
                               @click="dialog = false">
                            Cancelar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-card>


        <b-modal @ok="saveUser"  @cancel="currentItem = null" id="modal-1"
                 :title="(isNew ? 'Registro' : 'Actualización') + ' de usuario'" size="xl" ref="modal">
            <user-form  ref="userForm" :currentItem="currentItem" :isNew="isNew" :admin="true" ></user-form>

        </b-modal>

    </v-app>

</template>

<script>
    import {SET_BREADCRUMB} from "@/store/breadcrumbs.module";
    import service from "@/service/users.service"


    export default {
        name: "UserList",
        components: {
            userForm :()=> import('./UserForm')
        },

        data() {
            return {
                editing: true,
                isNew: false,
                dialog: false,
                loading: false,
                search: '',
                headers: [
                    {
                        text: 'Nombre',
                        align: 'left',
                        sortable: true,
                        value: 'firstname',
                    }, {
                        text: 'Apellido',
                        align: 'left',
                        sortable: true,
                        value: 'lastname',
                    },{
                        text: 'Documento',
                        align: 'left',
                        sortable: true,
                        value: 'documento',
                    },
                    {
                        text: 'Email',
                        align: 'left',
                        sortable: false,
                        value: 'email',
                    }, {
                        text: 'Teléfono',
                        align: 'left',
                        sortable: false,
                        value: 'celular',
                    },
                    {
                        text: 'Rol',
                        align: 'left',
                        sortable: true,
                        value: 'rol_name',
                    },
                    {
                        text: 'Numero de contratos',
                        sortable: true,
                        value: 'business_info.contratos_generados',
                    },
                    {text: 'Actions', value: 'actions', sortable: false},


                ],
                users: [],
                currentItem: {},
            }
        },
        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [
                { title: "Sistema"}, {title: "Usuarios"}
            ]);
            this.getData();

        },
        methods: {
            saveUser(e) {
                const result =  this.$refs.userForm.submit(e);
                result.then(() => {
                    this.$bvModal.hide('modal-1');
                    this.getData()
                }).catch(() => {
                }).finally(() => {
                })

            },
            getData() {
                this.users = [];
                this.loading = true;
                service.get().then(x => {
                    this.users = x.data.data;
                    this.loading = false;
                });
            },
            editItem(item) {
                this.isNew = false;
                this.currentItem = item;
                this.$refs.modal.show();
            },
            confirmDelete(item) {
                this.dialog = true;
                this.currentItem = item;
            },
            newUser() {
                this.isNew = true;
                this.currentItem = null;
            },
            deleteItem() {
                this.dialog = false;
                this.loading = true;
                const index = this.users.findIndex(x => {
                    return x.id === this.currentItem.id;
                });
                service.delete(this.currentItem.id).then(() => {
                    this.users.splice(index, 1);
                    this.loading = false;
                    this.currentItem = {}
                })
            }
        }
    }
</script>

<style scoped>
</style>
